









































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import RateUsComponent from "./RateUs.component";

@Component
export default class RateUs extends Mixins(RateUsComponent) {}
