import { Component, Vue } from "vue-property-decorator";
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'

import 'vue2-datepicker/index.css';
import { GET_RATEUS, GET_COMMENT } from "../../../api/account";

Vue.use(VModal, { componentName: 'modal' })
@Component({
    components: {
        DatePicker
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})
default class RateUs extends Vue {
    $axios: any;
    private model: Model;
    private commentData: any = []
    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate()
    }

    private rate = {
        customerService: 0,
        gameplay: 0,
        graphicDesign: 0,
        networkConnection: 0,
        videoQuality: 0
    }

    private paginate = {
        page: 1,
        limit: 10
    }
    private pageSize = 0;
    private paginateShow = false;
    private dataLoading = false;

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {

        this.addRouteName()
        this.dataLoading = true
        await this.getRateUs()
        await this.getComment(this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    async getRateUs()
    {
        const lists = await GET_RATEUS()
        if(lists['data'])
        {
        for(const value of lists['data'])
        {
            this.rate.customerService += value['customerService'] ? parseInt(value['customerService']) : 0
            this.rate.gameplay += value['gameplay'] ? parseInt(value['gameplay']) : 0
            this.rate.graphicDesign += value['graphicDesign'] ? parseInt(value['graphicDesign']) : 0
            this.rate.networkConnection += value['networkConnection'] ? parseInt(value['networkConnection']) : 0
            this.rate.videoQuality += value['videoQuality'] ? parseInt(value['videoQuality']) : 0
        }
        if(lists['data'].length > 0)
        {
            this.rate.customerService = this.rate.customerService / lists['data'].length
            this.rate.gameplay = this.rate.gameplay / lists['data'].length
            this.rate.graphicDesign = this.rate.graphicDesign / lists['data'].length
            this.rate.networkConnection = this.rate.networkConnection / lists['data'].length
            this.rate.videoQuality = this.rate.videoQuality / lists['data'].length
        }
        }
    }

    public async filterCommentData() {
        this.paginate.page = 1
        await this.getComment(this.paginate.page, this.paginate.limit)
    }

    public async selectDate(option: string) {
        switch (option) {
            case 'option1':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).subtract(1, 'days').toDate()
                break;
            case 'option2':
                this.dateFilter.startDate = moment().local().startOf('day').toDate()
                this.dateFilter.endDate = moment().local().endOf('day').toDate()
                break;
            case 'option3':
                this.dateFilter.startDate = moment().local(true).startOf('day').subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('day').subtract(1, 'days').toDate()
                break;
            case 'option4':
                this.dateFilter.startDate = moment().local(true).startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('week').toDate()
                break;
            case 'option5':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'week').startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'week').endOf('week').toDate()
                break;
            case 'option6':
                this.dateFilter.startDate = moment().local(true).startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('month').toDate()
                break;
            case 'option7':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'months').startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'months').endOf('month').toDate()
                break;
            case 'option8':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).add(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).add(1, 'days').toDate()
                break;
            default:
                break;
        }
        await this.getComment(this.paginate.page, this.paginate.limit)
    }

    public async clickCallback(number: number) {
        this.paginate.page = number
        await this.getComment(this.paginate.page, this.paginate.limit)
    }

    private async getComment(page: number, limit: number) {
        this.commentData = []
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        const lists = await GET_COMMENT((this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString(), page, limit)
        this.commentData = lists.data[0];

        if (this.commentData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.commentData.metadata.length; i++, k++) {
                this.commentData.metadata[i].no = k - this.paginate.limit
            }
            if (this.commentData.pageInfo[0].count > this.paginate.limit) {
                this.paginateShow = true
            }
            this.pageSize = this.commentData.pageInfo[0].size;
        } else {
            this.commentData = []
            this.paginateShow = false
        }
        console.log(this.commentData)
        loader.hide()
    }
}

export default RateUs